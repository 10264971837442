import { DecafClient } from '@decafhub/decaf-client';
import { Id } from '../../commons/types';
import { SDate } from '../../prelude/datetime';
import { isNothing } from '../../prelude/maybe';
import { SimpleChoices } from '../../prelude/simple-choice';

export interface PerformanceQuery {
  start: SDate;
  end: SDate;
  frequency?: TimeSeriesFrequency;
  overlayextval?: boolean;
  artifacts?: Id[];
  benchmarks?: Id[];
  portfolios?: Id[];
  shareclasses?: Id[];
  teams?: Id[];
  portfoliogroups?: Id[];
  accounts?: Id[];
}

export interface Performance {
  result: PerformanceResult;
  summary: PerformanceSummary;
}

export type PerformancePeriod = string;

export interface PerformanceSummary {
  periods: PerformancePeriod[];
  records: PerformanceSummaryItem[];
}

export interface PerformanceSummaryItem {
  symbol: string;
  metric: ReturnStatisticsMetric;
  values: PerformanceSummaryItemValue;
}

export type PerformanceSummaryItemValue = Record<PerformancePeriod, number | undefined>;

export interface PerformanceResult {
  levels: TimeSeries;
  returns: TimeSeries;
  indexed: TimeSeries;
  statistics: {
    univariate: {
      portfolios: InstanceStatistics[];
      shareclasses: InstanceStatistics[];
      benchmarks: InstanceStatistics[];
      artifacts: InstanceStatistics[];
    };
  };
}

export interface TimeSeries {
  index: SDate[];
  columns: string[];
  data: number[][];
}

export interface InstanceStatistics {
  label: string;
  stats: PeriodStatistics[];
}

export interface PeriodStatistics {
  label: string;
  stats: ReturnStatistics;
}

export type ReturnStatistics = Record<ReturnStatisticsMetric, number | undefined>;

export type ReturnStatisticsMetric = 'return' | 'stddev' | 'sharpe' | 'maxddown';

export type TimeSeriesFrequency = 'daily' | 'weekly' | 'monthly';

export const TimeSeriesFrequencies: SimpleChoices<TimeSeriesFrequency> = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

/**
 * Attempts to retrieve and compile remote report data.
 *
 * @param client barista client.
 * @returns Report data.
 */
export async function request(client: DecafClient, query: PerformanceQuery): Promise<Performance> {
  // Prepare parameters:
  const params = {
    start: query.start,
    end: query.start > query.end ? query.start : query.end,
    frequency: query.frequency || 'daily',
    overlayextval: isNothing(query.overlayextval) ? true : query.overlayextval,
    artifacts: query.artifacts || [],
    benchmarks: query.benchmarks || [],
    portfolios: query.portfolios || [],
    shareclasses: query.shareclasses || [],
    teams: query.teams || [],
    portfoliogroups: query.portfoliogroups || [],
    accounts: query.accounts || [],
  };

  // Get raw items:
  const { data } = await client.barista.get<PerformanceResult>('/performance/', { params });

  // Done, return:
  return { result: data, summary: compilePerformanceSummary(data) };
}

function compilePerformanceSummary(data: PerformanceResult): PerformanceSummary {
  // Get all instance statistics:
  const instanceStatistics = [
    ...data.statistics.univariate.artifacts,
    ...data.statistics.univariate.benchmarks,
    ...data.statistics.univariate.portfolios,
    ...data.statistics.univariate.shareclasses,
  ].sort((a, b) => a.label.localeCompare(b.label));

  // Attempt to get periods:
  // @ts-expect-error ts2532
  const periods = instanceStatistics.length == 0 ? [] : instanceStatistics[0].stats.map((x) => x.label);

  // Iterate over records and prepare summary items:
  const records: PerformanceSummaryItem[] = [];
  instanceStatistics.forEach((inst) => {
    const agg = {} as Record<ReturnStatisticsMetric, PerformanceSummaryItemValue>;

    inst.stats.forEach(({ label, stats }) => {
      // @ts-expect-error ts2345
      Object.entries(stats).forEach(([metric, value]: [ReturnStatisticsMetric, number | undefined]) => {
        if (!(metric in agg)) {
          agg[metric] = {} as PerformanceSummaryItemValue;
        }
        agg[metric][label] = value;
      });
    });

    // Populate records:
    records.pushObjects(
      // @ts-expect-error ts2345
      Object.entries(agg).map(([metric, values]: [ReturnStatisticsMetric, PerformanceSummaryItemValue]) => ({
        symbol: inst.label,
        metric,
        values,
      }))
    );
  });

  // Don, return:
  return { periods, records };
}
